import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [fileContent, setFileContent] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const letter = `IIn the love garden, under the soft moonlight,
Where love blossoms, and whispers are sweet,
There you are, so lovely and bright,
My heart sings with joy, such a delightful sight.

Your face, a vision of radiant beauty,
Where every glance feels like a sweet duty,
In your smile, I find endless grace,
Lost in your eyes, I find my place.

Your lips, soft as a gentle breeze,
A kiss from them, a moment to seize,
In their touch, I find ecstasy,
Lost in their sweetness, I find my reverie.

Your boobs, a sanctuary of love,
Where my dreams take flight like a dove,
In your embrace, I find solace and bliss,
Lost in your warmth, I find my kiss.

And oh, your curves, a sight to behold,
In their rhythm, my desires unfold,
In your sway, I find my home,
Lost in your allure, I never want to roam.`;

  useEffect(() => {
    if (authenticated) {
      let index = 0;
      const interval = setInterval(() => {
        if (index < letter.length - 1) {
          setFileContent((prevContent) => prevContent + letter[index]);
          index++;
        } else {
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [authenticated, letter]);

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    const secrets = ["ada", "adaora", "adaorah"];
    if (secrets.includes(secretCode.toLocaleLowerCase())) {
      setAuthenticated(true);
    } else {
      alert("Identity not recognized. Please enter the correct name.");
    }
  };

  const handleChange = (e) => {
    setSecretCode(e.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        {!authenticated && (
          <form
            onSubmit={handleCodeSubmit}
            style={{
              textAlign: "center",
            }}
          >
            <input
              type="password"
              placeholder="What's your name"
              value={secretCode}
              onChange={handleChange}
              style={{ margin: "5px", padding: "5px", borderRadius: "5px" }}
            />
            <button
              type="submit"
              style={{
                backgroundColor: "lightcoral",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        )}
        {authenticated && (
          <>
            <strong style={{ textAlign: "center" }}>Just for you</strong>
            <p style={{ whiteSpace: "pre-line" }}>{fileContent}</p>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
